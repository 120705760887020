@import '../../scss/mixin.scss';

.table {
    width: 40%;
    padding: 1.5rem 0 1.5rem 10vw;
    border-right: 1px solid rgba(black, 0.25);
    position: relative;
    @include mobile {
        width: 100%;
        padding: 1.5rem;
    }
    @include tablet {
        width: 100%;
        padding: 1.5rem;
    }

    @include small-desktop {
        width: 100%;
        padding: 1.5rem;
    }

    .row {
        display: flex;
        justify-content: flex-start;
        border-bottom: 1px solid rgba(black, 0.25);
        align-items: center;
        height: 2.25em;
        .cell {
            width: 33%;
            padding-left: 1.5rem;
            
            p {
                text-transform: capitalize;
                font-size: 14px;
                @include mobile{
                    font-size: 12px;
                }
            }
        }
    }
    .row:first-child {
        .cell {
            p {
                text-transform: uppercase;
                color: $cyan;
            }
        }
    }
    .row:last-child {
        border-bottom: none;
        .cell {
            p {
                text-transform: uppercase;
                color: $cyan;
            }
        }
    }
    .row.floor {
        cursor: pointer;
        color: black;
        text-decoration: none;
        &:hover {
            background-color: rgba($cyan, 0.25);
        }
    }
    .row.floor:hover{
        background-color: rgba($cyan, 0.25);
        cursor: pointer;
    }
 
}   .approx{
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 1.5rem;
        p {
            font-size: 10px;
            color: $cyan;
        }
    }