@import "./mixin.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    background-color: $beige;
}

@font-face {
    font-family: "Regular";
    src: url("../../public/fonts/brockmann-regular-webfont.woff2") format("woff2"),
        url("../../public/fonts/brockmann-regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}p{
    font-family: 'Regular';
}
h4{
    letter-spacing: 0.1em;
    font-family: "gazzetta-variable", sans-serif;
    font-variation-settings: "wght" 400;
    font-weight: 400;
}

h1{
    font-family: 'Regular';
    font-size: 50px;
    text-align: center;
}
h2{
    font-family: 'Regular';
}
h3{
    font-family: 'Regular';
}
h5{
    font-family: 'Regular';
}