@import "../../scss/mixin.scss";

body {
  color: $grey;
}

.video-section {
  background-color: $orange;
  .video-wrapper {
    width: 80vw;
    margin: 0 auto;
    border: 0;
    position: relative;
    top: 200px;
    iframe {
      border: 0;
      display: block;
    }
    @include mobile {
      width: calc(100vw - 3rem);
      top: 100px;
    }
  }
  img {
    max-width: 100%;
    margin: 0 auto;
    display: inherit;
  }
}

.spacer {
  height: 200px;
  width: 80vw;
  margin: 0 auto;
  border-left: 1px solid rgba(0, 0, 0, 0.25);
  border-right: 1px solid rgba(0, 0, 0, 0.25);
  @include mobile {
    width: calc(100vw - 3rem);
    height: 100px;
  }
}

.title-mask {
  overflow-y: hidden;
  .hero-image {
    width: 100vw;
    height: 100vh;
    mask-image: url("../../../public/images/mask.png");
    mask-repeat: no-repeat;
    mask-position: 50% 25%;
    mask-border: 1px solid black;
    transition: mask-size 0.5s ease;
    @include mobile {
      max-width: 100vw;
      mask-position: 50% 50%;
      height: 100vh;
    }
  }
}

.title-wrapper {
  position: absolute;
  left: calc(50% - 14vw);
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  transition: width 0.5s ease left 0.5s ease bottom 0.5s ease;

  .title-img {
    padding: 1.5rem;
    width: 100%;
  }
}
.location {
  position: absolute;
  left: calc(50% - 7vw);
  width: 14vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  transition: bottom 0.5s ease;

  h1 {
    padding-bottom: 5vh;
    width: 200%;
    font-size: 60px;
    @include mobile {
      width: fit-content;
      font-size: 60px;
      text-align: center;
    }
    @include tablet {
      font-size: 45px;
    }
  }
  .loc-border {
    width: 16vw;
    border: 1px solid white;
    border-top: 0;
    border-bottom: 0;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include mobile {
      width: calc(40vw);
    }
  }
}
.touch {
  position: absolute;
  top: 3rem;
  right: 3rem;
  height: 40px;
  z-index: 2;
  @include mobile {
    top: 1.5rem;
    right: 1.5rem;
    height: 30px;
  }
  cursor: pointer;
  .left {
    height: 100%;
    display: flex;
    align-items: center;
    border: 1px solid rgba(black, 0.25);
    border-radius: 25px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0 1.5rem 0 0.75rem;
    border-right: 0;
    p {
      text-transform: uppercase;
      font-size: 14px;
      @include mobile {
        font-size: 11px;
      }
    }
  }
  .right {
    border: 1px solid rgba(black, 0.25);
    border-radius: 25px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    display: flex;
    align-items: center;
    height: 100%;
    background-color: $orange;
    img {
      margin-left: -10px;
      width: 100%;
      height: 50%;
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 0;
}

.copy {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 6rem 0;
  @include mobile {
    padding: 3rem 1.5rem;
    align-items: flex-start;
  }
  h3 {
    color: $cyan;
    font-size: 30px;
    padding: 0 0 1.5rem 0;
    font-family: "gazzetta-variable", sans-serif;
    font-variation-settings: "wght" 400;
    letter-spacing: 0.1em;
    font-weight: 400;
    text-transform: uppercase;
    @include mobile {
      font-size: 22px;
      text-align: center; 
      margin: 0 auto;
    }
  }
  p {
    font-size: 30px;
    width: 50%;
    text-align: center;
    @include mobile {
      width: 100%;
      font-size: 16px;
    }
    @include tablet {
      width: 70%;
      line-height: 1.3;
      font-size: 18px;
    }
    @include small-desktop {
      line-height: 1.3;
      font-size: 22px;
    }
  }
}

.key-features {
  background-color: $orange;
  border-top: 1px solid rgba(black, 0.25);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  @include tablet {
    text-align: center;
  }

  .title {
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $beige;
    width: 40vw;

    h3 {
      text-transform: capitalize;
      font-size: 50px;
      @include tablet {
        font-size: 45px;
        padding-bottom: 1em;
      }
    }
    h4 {
      text-transform: uppercase;
      font-size: 20px;
      padding-bottom: 1em;
    }

    @include mobile {
      width: calc(100vw - 3rem);
      border-bottom: 1px solid rgba(black, 0.25);
    }
    .title-border {
      width: 40vw;
      height: 0%;
      border-left: 1px solid rgba(black, 0);
      border-right: 1px solid rgba(black, 0);
      transition: border-color 1s ease, height 4s ease;
      position: absolute;
      top: 0;
      @include mobile {
        width: calc(100vw - 3rem);
      }

      &.animate {
        height: 100%;
        border-left: 1px solid rgba(black, 0.25);
        border-right: 1px solid rgba(black, 0.25);
      }
    }
  }

  .feature-items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 80vw;
    flex-basis: 25%;
    @include mobile {
      width: calc(100vw - 3rem);
      flex-basis: 50%;
    }
    .feature-item-wrapper {
      border-bottom: 1px solid rgba(black, 0.25);
      width: 20vw;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 3rem 0;
      @include mobile {
        width: 40vw;
        padding: 1.5rem;
        &:nth-child(2n) {
          border-right: none;
        }
      }
      img {
        height: 100px;
      }
      p {
        text-transform: uppercase;
        font-size: 16px;
        padding-top: 1em;
        max-width: 75%;
        text-align: center;
        @include tablet {
          line-height: 1.3;
          font-size: 14px;
        }
        @include mobile {
          font-size: 14px;
        }
      }
    }
    .feature-item-wrapper:nth-child(3) {
      position: relative;
      .borders {
        border-left: 1px solid rgba(black, 0);
        height: 0%;
        position: absolute;
        bottom: 0;
        width: 100%;
        transition: border-color 1s ease, height 2s ease;
        &.animate {
          height: 100%;
          border-left: 1px solid rgba(black, 0.25);
          @include mobile {
            border-left-color: rgba(black, 0);
          }
        }
      }
    }
    @include mobile {
      .feature-item-wrapper:nth-child(2n) {
        border-left: 1px solid rgba(black, 0.25);
        .borders {
          border-left: 1px solid rgba(black, 0);
          border-right: 1px solid rgba(black, 0);
          height: 0%;
          position: absolute;
          bottom: 0;
          width: 100%;
          transition: border-color 1s ease, height 3s ease;
          &.animate {
            height: 100%;
            border-left: 1px solid rgba(black, 0.25);
            @include mobile {
              border-right-color: rgba(black, 0.25);
              border-left-color: rgba(black, 0);
            }
          }
        }
      }
    }

    .feature-item-wrapper:nth-child(4n) {
      border-right: none;
    }
    .feature-item-wrapper:nth-child(5) {
      margin-bottom: 1.5rem;
      border-bottom: none;
      @include mobile {
        margin-bottom: 0;
        border-bottom: 1px solid rgba(black, 0.25);
      }
    }
    .feature-item-wrapper:nth-child(6) {
      border-bottom: none;
      @include mobile {
        margin-bottom: 0;
        border-bottom: 1px solid rgba(black, 0.25);
      }
    }
    .feature-item-wrapper:nth-child(7) {
      position: relative;
      .borders {
        border-left: 1px solid rgba(black, 0);
        height: 0%;
        position: absolute;
        top: 0;
        width: 100%;
        transition: border-color 1s ease, height 3s ease;
        &.animate {
          height: 100%;
          border-left: 1px solid rgba(black, 0.25);
          @include mobile {
            border-left-color: rgba(black, 0);
          }
        }
      }
      border-bottom: none;
      margin-bottom: 1.5rem;
    }
    .feature-item-wrapper:nth-child(8) {
      border-bottom: none;
      margin-bottom: 1.5rem;
    }
    .feature-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.floorplan {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    width: 80vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 3rem 0;
    position: relative;
    .title-border {
      position: absolute;
      top: 0;
      width: 100%;
      border-left: 1px solid rgba(black, 0);
      border-right: 1px solid rgba(black, 0);
      transition: border-color 1s ease, height 3s ease;
      height: 0;

      &.animate {
        height: 100%;
        border-left: 1px solid rgba(black, 0.25);
        border-right: 1px solid rgba(black, 0.25);
      }
    }

    h4 {
      text-transform: uppercase;
      font-size: 20px;
      color: $cyan;
      padding-bottom: 1em;
    }
    h2 {
      font-size: 50px;
      @include tablet {
        text-align: center;
        font-size: 35px;
      }
    }
    
    @include mobile {
      width: calc(100vw - 3rem);
      align-items: center;
      display: flex;
      h4 {
        font-size: 20px;
        padding: 0.5rem 1.5rem;
      }

      h2 {
        font-size: 20px;
        padding: 0 1.5rem;
      }
    }
  }
  .table-content {
    .table-content-border {
      width: 0%;
      border-top: solid 1px rgba(black, 0);
      position: absolute;
      transition: border-color 1s ease, width 3s ease, left 3s ease;
      z-index: -1;
      left: 50%;
      &.animate {
        left: 0%;

        width: 100%;
        height: 100%;
        border-top-color: rgba(black, 0.25);
      }
    }
    width: 100%;
    display: flex;
    .building {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 1.5rem;
      img {
        width: 100%;
      }
    }
    @include mobile {
      flex-direction: column;
    }
    @include tablet {
      flex-direction: column;
    }
    @include small-desktop {
      flex-direction: column;
    }
  }
  .floorplan-hero {
    position: relative;
    .floorplan-border {
      width: 0%;
      border-top: solid 1px rgba(black, 0);
      border-bottom: solid 1px rgba(black, 0);
      position: absolute;
      transition: border-color 1s ease, width 3s ease;
      z-index: -1;
      &.animate {
        width: 100%;
        height: 100%;
        border-top-color: rgba(black, 0.25);
        border-bottom-color: rgba(black, 0.25);
      }
    }
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .floorplan-content {
    .content-border {
      height: 0%;
      width: 100%;
      border-left: 1px solid rgba(black, 0);
      border-right: 1px solid rgba(black, 0);
      transition: border-color 1s ease, height 3s ease;
      bottom: 0;
      position: absolute;
      &.animate {
        border-left-color: rgba(black, 0.25);
        border-right-color: rgba(black, 0.25);
        height: 100%;
      }
    }
    width: 80vw;
    display: flex;
    justify-content: center;
    position: relative;
    img {
      padding: 5rem 5rem 7.5rem;
      height: 85vh;

      aspect-ratio: auto;
      @include mobile {
        width: 100%;
        height: auto;
        padding: 3rem 1.5rem 5rem 1.5rem;
      }

      @include tablet {
        max-width: 100%;
        height: auto;
      }

      &.lower-ground {
        padding-bottom: 7.5rem;
        @include mobile {
          padding-bottom: 5rem;
          height: 318px;
          width: auto;
        }
      }
    }
    @include mobile {
      width: 100vw;
      margin: 0 1.5rem;
    }
    
    .compass {
      width: 25px;
      position: absolute;
      top: 3rem;
      right: 3rem;
      height: fit-content;
    }
    .key {
      position: absolute;
      top: 3rem;
      left: 3rem;
      @include mobile {
        top: 1.5rem;
        left: 1.5rem;
      }

      .row {
        display: flex;
        padding: 0.5rem 0;
        align-items: center;
        .circle {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          &.grey {
            background-color: grey;
          }
          &.blue {
            background-color: $cyan;
          }
        }
        p {
          padding-left: 0.5rem;
          text-transform: uppercase;
          font-size: 14px;
          @include mobile {
            font-size: 11px;
          }
        }
      }
    }
  }

  .btn-wrapper {
    position: absolute;
    bottom: 3rem;
    left: calc(50% - 50px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    @include mobile {
      bottom: 1.5rem;
    }

    .btn {
      cursor: pointer;
      border: 1px solid rgba(black, 0.25);
      padding: 0.75rem 1.75rem;
      letter-spacing: 2px;
      @include mobile {
        padding: 0.5rem 1rem;
        p {
          font-size: 12px;
          @include mobile {
            font-size: 10px;
          }
        }
      }
      p {
        color: $cyan;
        text-transform: uppercase;
      }
    }
    .btn.active {
      background-color: $cyan;
      p {
        color: white;
      }
    }
    .btn:first-child {
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
    }
    .btn:last-child {
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
      border-left: none;
    }
    .btn:hover {
      background-color: $cyan;
      p {
        color: white;
      }
    }
    .btn.gray {
      opacity: 0.5;
      p {
        color: gray;
      }
      cursor: not-allowed;
    }
    .btn.gray:hover {
      background-color: transparent;
    }
  }

  .floorplan-btns {
    display: flex;
    width: 100%;
    height: 5vh;
    justify-content: center;
    padding-bottom: 10rem;
    .btm-btns {
      display: flex;
      width: 80vw;
      bottom: 0;
      height: 5vh;
      @include mobile {
        width: 100vw;
        padding: 0 1.5rem;
        flex-wrap: wrap;
      }
      .btn {
        display: flex;
        cursor: pointer;
        height: 100%;
        justify-content: center;
        align-items: center;
        width: 12.5%;
        border-top: solid 1px rgba(black, 0.25);
        border-right: solid 1px rgba(black, 0.25);
        margin-top: -1px;
        background-color: $beige;
        @include mobile {
          width: calc(100% / 3);
          border-left: 1px solid rgba(black, 0.25);
          border-right: none;
          &:nth-child(3n) {
            border-right: 1px solid rgba(black, 0.25);
          }
        }
        p {
          color: black;
          text-transform: uppercase;
          @include mobile {
            font-size: 11px;
          }
          @include tablet {
            font-size: 11px;
          }
        }
      }
      .btn:first-child {
        border-left: 1px solid rgba(black, 0.25);
      }

      .btn.active {
        border-bottom: 1px solid rgba(black, 0.25);
        @include tablet {
          border-top: none;
        }
        p {
          color: $cyan;
        }
      }
      .btn:hover {
        p {
          color: $cyan;
        }
      }
    }
  }
}

.bottom-lines-wrapper {
  width: 100%;
  height: calc(30vh + 1px);
  position: absolute;
  bottom: 0;
  @include mobile {
    height: calc(25vh + 5px);
  }
  &.inActive {
    display: none;
  }
  .lines-wrapper {
    width: 30vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @include mobile {
      width: 50vw;
    }
    .scroll {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 3rem;
      width: 100%;
      p {
        text-transform: uppercase;
        font-size: 14px;
      }
      img {
        transform: rotate(90deg);
        width: 40px;
      }
    }
    .lines {
      width: 100%;
      height: 0%;
      padding-top: 3rem;
      border-left: 1px solid rgba(black, 0);
      border-right: 1px solid rgba(black, 0);
      transition: border-color 4s ease, height 2s ease; /* Transition for border color and height */
      &.animate {
        height: 100%;
        border-left-color: rgba(black, 0.25);
        border-right-color: rgba(black, 0.25);
        animation-fill-mode: forwards; /* Animation will retain its ending values after it completes */
      }
    }
  }
  .bottom-lines {
    border-top: 1px solid rgba(black, 0);
    display: flex;
    justify-content: center;
    height: 100%;
    transition: border-color 1s ease, width 3s ease;
    &.animate {
      width: 100%;
      border-top-color: rgba(black, 0.25);
    }
  }
}
