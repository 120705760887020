@import "../../scss/global.scss";

.swiper {
  .swiper-img-back {
    height: 70vh;
    background-size: cover;
    background-position: 50% 50%;
    width: 100%;
    @include mobile {
      height: 40vh;
    }
  }

  .nav-wrapper {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    height: 150px;
    background-color: $orange;
    flex-direction: column;
    @include mobile{
      justify-content: center;
    }
    .borders-wrapper {
      width: 400px;
      height: 50px;
      position: relative;
      .borders {
        height: 0%;
        border-left: 1px solid rgba(black, 0);
        border-right: 1px solid rgba(black, 0);
        transition: border-color 1s ease, height 2s ease; /* Transition for border color and height */
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        &.animate {
          border-left-color: rgba(black, 0.25);
          border-right-color: rgba(black, 0.25);
          height: 100%;
        }
      }
      .borders2 {
        height: 0%;
        border-left: 1px solid rgba(black, 0);
        border-right: 1px solid rgba(black, 0);
        transition: border-color 1s ease, height 2s ease; /* Transition for border color and height */
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0; /* Start from the bottom */
        &.animate {
          border-left-color: rgba(black, 0.25);
          border-right-color: rgba(black, 0.25);
          height: 100%;
        }
      }
      content: " ";
    }
    .nav {
      width: fit-content;
      display: flex;
      justify-content: space-between;
      height: 100%;
      @include mobile {
        width: 100%;
        height: 50px;
      }

      .swiper-button-prev {
        display: flex;
        position: relative;
        width: 50px;
        content: url("../../../public/images/arrow.svg");
        transform: rotate(180deg);
      }
      .swiper-button-next {
        display: flex;
        position: relative;
        width: 50px;
        content: url("../../../public/images/arrow.svg");
      }
      .copy-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 50vw;
        padding: 0 3rem;
        @include mobile {
          padding: 0 1rem;
        }

        h6 {
          font-size: 20px;
          text-transform: uppercase;
          @include mobile {
            font-size: 12px;
          }
        }
        h6:nth-child(2) {
          padding: 0 0 0 1.5rem;
        }
      }
    }
  }
}
