@import "../../scss/mixin.scss";

.footer-hero {
  background-color: $navy;
  .top-pad {
    height: 5vw;
    width: 100%;
    display: flex;
    justify-content: center;
    @include mobile {
      height: 50px;
    }
    .footer-box {
      border-left: 1px solid rgba($green, 0); /* Initial state: invisible */
      border-right: 1px solid rgba($green, 0); /* Initial state: invisible */
      height: 0%;
      position: relative;
      width: 20vw;
      transition: border-color 1s ease, height 3s ease; /* Transition for border color and height */
      &.animate {
        height: 100%;
        border-left-color: rgba($green, 0.5); /* Fully drawn state */
        border-right-color: rgba($green, 0.5); /* Fully drawn state */
      }
    }
  }

  .footer-content {
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
    .footer-content-border{
      border-top: 1px solid rgba($green, 0);
      border-bottom: 1px solid rgba($green, 0);
      width: 0%;
      position: absolute;
      height: 100%;
      transition: border-color 1s ease, width 3s ease; /* Transition for border color and width */
      &.animate {
        width: 100%;
        border-top-color: rgba($green, 0.5);
        border-bottom-color: rgba($green, 0.5);
      }
    }
    .footer-box {
      border-left: 1px solid rgba($green, 0);
      border-right: 1px solid rgba($green, 0);
      width: 80vw;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      padding: 3rem 0;
      @include mobile {
        padding: 1.5rem;
        width: calc(100vw - 3rem);
      }
      .footer-borders{
        position: absolute;
        width: 100%;
        height: 0;
        top: 0;
        border-left: 1px solid rgba($green, 0);
        border-right: 1px solid rgba($green, 0);
        transition: border-color 1s ease, height 3s ease; /* Transition for border color and height */
        &.animate {
          height: 100%;
          border-left-color: rgba($green, 0.5);
          border-right-color: rgba($green, 0.5);
        }
      }
      img {
        width: 20%;
        padding: 1.5rem 0 3rem 0;
        @include mobile {
          width: 80%;
        }
      }
      .agents {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        align-items: center;
        flex-wrap: wrap;
        .agent {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          @include mobile {
            padding: 0.5rem 0;
          }
          a {
            color: $beige;
            text-decoration: none;
          }
          p {
            color: $beige;
            font-size: 20px;
            line-height: 28px;
            @include mobile {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
      .misrep {
        padding: 0 3rem;
        font-size: 10px;
        color: #F1EAE1;
        
        @include mobile {
          padding: 0;
        }
      }
      &.animate {
        height: 100%;
        border-left-color: rgba($green, 0.5); /* Fully drawn state */
        border-right-color: rgba($green, 0.5); /* Fully drawn state */
      }
    }
  }
}


.footer-borders-mis{
  position: absolute;
  width: 100%;
  height: 0;
  bottom: 0;
  border-left: 1px solid rgba($green, 0);
  border-right: 1px solid rgba($green, 0);
  transition: border-color 1s ease, height 3s ease; /* Transition for border color and height */
  &.animate {
    height: 100%;
    border-left-color: rgba($green, 0.5);
    border-right-color: rgba($green, 0.5);
  }
}