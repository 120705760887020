$orange: #EC6145;
$beige: #F1EAE1;
$navy: #203136;
$green: #49BFAA;
$cyan: #33C3A9;
$grey: #203136;

@mixin large-desktop {
    @media (min-width: 1500px) {
        @content;
    }
}


@mixin desktop {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin small-desktop {
    @media (min-width: 1024px) and (max-width: 1200px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: 767px) {
        @content;
    }
}